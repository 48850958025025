import { useMsal } from '@azure/msal-react';
import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { loginRequest } from 'src/auth/authConfig';
import useAccessToken from 'src/auth/useAccessToken';
import MarketplaceForm from 'src/components/MarketplaceForm';
import PurchaseInProgress from 'src/components/PurchaseInProgress';

const useStyles = makeStyles({
    loadingContainer: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
});

const PurchaseStateFactory = () => {
    const classes = useStyles();

    const [previousPurchaseInstance, setPreviousPurchaseInstance] = React.useState(null);
    const [previousPurchaseResolved, setPreviousPurchaseResolved] = React.useState(false);
    const [previousPurchaseResolveError, setPreviousPurchaseResolveError] = React.useState(null);
    const [purchaseConfirmedThisSession, setPurchaseConfirmedThisSession] = React.useState(false);

    const { accounts } = useMsal();
    const accessToken = useAccessToken(loginRequest.scopes);
    const claims = React.useMemo(() => accounts?.[0]?.idTokenClaims ?? {}, [accounts]);

    // Make request and try to get existing Purchase process
    React.useEffect(() => {
        const makeRequest = async () => {
            try {
                const response = await fetch('https://api.arena.fi/smartdialog-marketplace-ext/v1/resolve', {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                if (response.status === 200) {
                    const resp = await response.json();
                    setPreviousPurchaseInstance(resp);
                } else {
                    setPreviousPurchaseInstance(null);
                }
            } catch (error) {
                setPreviousPurchaseResolveError(error);
            } finally {
                setPreviousPurchaseResolved(true);
            }
        };

        if (claims?.sub && accessToken && !previousPurchaseResolved) {
            makeRequest();
        }
    }, [claims, accessToken, previousPurchaseResolved]);

    const refreshPurchase = React.useCallback(() => setPreviousPurchaseResolved(false), []);

    // Error resolving previous purchase
    if (previousPurchaseResolveError) {
        return (
            <div>
                <Typography variant="h2">
                    Oops something unexpected happened.
                </Typography>
            </div>
        );
    }

    // Loading
    if (!previousPurchaseResolved || !accessToken) {
        return <div className={classes.loadingContainer}><CircularProgress size={80} /></div>;
    }

    // Previous purchase found
    if (previousPurchaseInstance) {
        return <PurchaseInProgress instance={previousPurchaseInstance} purchaseConfirmedThisSession={purchaseConfirmedThisSession} />;
    }

    // No previous purchase found. Show form
    return <MarketplaceForm claims={claims} accessToken={accessToken} refreshPurchase={refreshPurchase} onConfirm={() => setPurchaseConfirmedThisSession(true)} />;
};

export default PurchaseStateFactory;