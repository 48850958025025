import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
    box: {
        backgroundColor: theme.palette.background.blue,
        flex: 1,
        maxWidth: '100%',
        overflow: 'visible',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        '& *': {
            color: theme.palette.white
        },
        padding: 50,
        height: '100%'
    },
    image: {
        maxWidth: '80%'
    },
    stateText: {
        textAlign: 'center',
        paddingTop: '3vh',
        color: '#ccc',
        maxWidth: '80%',
        overflowWrap: 'break-word'
    }
}));

const AuthenticationStatusPage = ({ children }) => {
    const classes = useStyles();

    return (
        <>
            <Helmet>
                <title>SmartDialog Purchase</title>
            </Helmet>
            <Box className={classes.box}>
                <img
                    className={classes.image}
                    alt="Logo"
                    src="/images/logos/smartdialog_logo.png"
                />
                {typeof children === 'string' && (
                    <Typography
                        gutterBottom
                        variant="h3"
                        className={classes.stateText}
                    >
                        {children}
                    </Typography>
                )}
                {typeof children !== 'string' && children}
            </Box>
        </>
    );
};

AuthenticationStatusPage.propTypes = {
    children: PropTypes.any
};

export default AuthenticationStatusPage;
