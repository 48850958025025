import React from 'react';
import { theme } from 'src/theme';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import 'src/assets/main.scss';
import { msalInstance } from 'src/auth/PublicClientApplication';
import { MsalProvider } from '@azure/msal-react';
import IsAuthenticated from 'src/auth/IsAuthenticated';
import SnackbarProvider from 'src/components/Snackbar';
import PurchaseStateFactory from 'src/components/PurchaseStateFactory';
import MainLayout from 'src/components/MainLayout';

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider>
                <MsalProvider instance={msalInstance}>
                    <IsAuthenticated>
                        <MainLayout>
                            <PurchaseStateFactory />
                        </MainLayout>
                    </IsAuthenticated>
                </MsalProvider>
            </SnackbarProvider>
        </ThemeProvider>
    );
};

export default App;
