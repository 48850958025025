import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { default as MuiSnackBar } from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseRounded';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
    alert: {
        minWidth: '200px'
    },
    close: {
        position: 'absolute',
        right: '0',
        top: '0'
    }
}));

const SnackbarContext = React.createContext();

const defaultState = {
    snackOpen: false,
    snackTitle: '',
    snackText: '',
    snackSeverity: '',
    autoHideDuration: 10000
};

const SnackbarProvider = ({ children }) => {
    const classes = useStyles();
    const [snackState, setSnackState] = React.useState(defaultState);
    const { snackOpen, snackTitle, snackText, snackSeverity, autoHideDuration } = snackState;

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackState({ ...snackState, snackOpen: false });
    };

    const showSnack = (snackText, snackTitle, snackSeverity, options) => {
        setSnackState((state) => ({ ...state, ...(options ? { ...options } : defaultState), snackOpen: true, snackText, snackTitle, snackSeverity }));
    };

    const success = React.useCallback((snackText, options) => showSnack(snackText, 'Success', 'success', options), []);
    const error = React.useCallback((snackText, options) => showSnack(snackText && snackText !== '' ? snackText : 'Oops an error occurred', 'Error', 'error', { ...options, autoHideDuration: 20000 }), []);
    const info = React.useCallback((snackText, options) => showSnack(snackText, 'Info', 'info', options), []);
    const warning = React.useCallback((snackText, options) => showSnack(snackText, 'Warning', 'warning', options), []);

    return (
        <SnackbarContext.Provider
            value={{
                success,
                error,
                info,
                warning
            }}
        >
            <>
                <MuiSnackBar
                    anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                    open={snackOpen}
                    onClose={handleSnackClose}
                    autoHideDuration={autoHideDuration}
                >
                    <Alert
                        severity={snackSeverity}
                        className={classes.alert}
                        action={(
                            <IconButton
                                aria-label="close"
                                onClick={() => {
                                    handleSnackClose();
                                }}
                                className={classes.close}
                            >
                                <Tooltip title="Close">
                                    <CloseIcon />
                                </Tooltip>
                            </IconButton>
                        )}
                    >
                        <AlertTitle>{snackTitle}</AlertTitle>
                        {snackText}
                    </Alert>
                </MuiSnackBar>
                {children}
            </>
        </SnackbarContext.Provider>
    );
};

SnackbarProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

const useSnackbar = () => {
    const context = React.useContext(SnackbarContext);

    if (context === undefined) {
        throw new Error('useSnackbar must be used within a SnackbarProvider');
    }
    return context;
};

export { useSnackbar };

export default SnackbarProvider;