import { Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: '#254a8e',
        display: 'flex'
    },
    headerText: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '&& > *': {
            color: theme.palette.white
        }
    },
    logo: {
        maxWidth: '20%',
        minWidth: 100,
        padding: theme.spacing(2)
    },
    card: {
        maxWidth: '100%',
        width: '1000px',
        minHeight: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: theme.spacing(2),
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    childrenContainer: {
        display: 'flex',
        flex: 1
    }
}));

const MainLayout = ({ children }) => {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardContent className={classes.header}>
                <img
                    src="/images/logos/smartdialog_logo_vertical_white.png"
                    alt="Arena Interactive logo"
                    className={classes.logo}
                />
                <div className={classes.headerText}>
                    <Typography variant="h1">Get a SmartDialog subscription</Typography>
                </div>
            </CardContent>
            <CardContent className={classes.childrenContainer}>
                {children}
            </CardContent>
        </Card>
    );
};

MainLayout.propTypes = {
    children: PropTypes.any
};

export default MainLayout;