import { Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const PurchaseInProgress = ({ instance, purchaseConfirmedThisSession }) => {
    const statusText = React.useMemo(() => {
        const runtimeStatus = instance?.runtimeStatus;
        const purchaseStatus = instance?.customStatus?.PurchaseStatus;

        if (runtimeStatus === 1) {
            // Instance is complete
            switch (purchaseStatus) {
                case 1:
                case 3:
                case 5:
                    // Rejected
                    return 'Rejected';
                case 7:
                    // Complete PurchaseStatus, redirect to SD
                    window.location.href = 'https://app.smartdialog.ai';
                    return 'Finished';
                default:
                    return 'Awaiting approval';
            }
        } else {
            // Instance is running
            return 'Awaiting approval';
        }
    }, [instance]);

    return (
        <div>
            <Typography variant="h2">
                Thank you for submitting a request to get a SmartDialog subscription!
                <br />
                <br />
                We will inform you via email once the account has been created and approved. You can login here later to see the status of the account.
                <br />
                <br />
                Your request has the following status:
                {' '}
                <b>{statusText}</b>
                {purchaseConfirmedThisSession && (
                    <>
                        <br />
                        <br />
                        Note: We have also sent you an email including a link for checking your subscription progress
                    </>
                )}
            </Typography>
        </div>
    );
};

PurchaseInProgress.propTypes = {
    instance: PropTypes.object.isRequired,
    purchaseConfirmedThisSession: PropTypes.bool
};

export default PurchaseInProgress;