import { BrowserAuthError, InteractionRequiredAuthError, InteractionType, ServerError } from '@azure/msal-browser';
import { useIsAuthenticated, useMsalAuthentication } from '@azure/msal-react';
import React from 'react';
import { loginRequest } from 'src/auth/authConfig';
import useAccessToken from 'src/auth/useAccessToken';
import PropTypes from 'prop-types';
import AuthenticationStatusPage from 'src/auth/AuthenticationStatusPage';
import { Typography } from '@material-ui/core';

const IsAuthenticated = ({ children }) => {
    const { login, error } = useMsalAuthentication(InteractionType.Redirect, loginRequest);
    const isAuthenticated = useIsAuthenticated();
    const accessToken = useAccessToken(loginRequest.scopes);

    React.useEffect(() => {
        if (error && error instanceof InteractionRequiredAuthError) {
            login(InteractionType.Redirect, loginRequest);
        } else if (error instanceof BrowserAuthError) {
            login(InteractionType.Redirect, loginRequest);
        } else if (error instanceof ServerError) {
            login(InteractionType.Redirect, loginRequest);
        }
    }, [error, login, loginRequest]);

    if (isAuthenticated && accessToken) {
        return children;
    }

    if (
        error &&
        !(error instanceof InteractionRequiredAuthError) &&
        !(error instanceof BrowserAuthError) &&
        !(error instanceof ServerError)
    ) {
        return (
            <AuthenticationStatusPage>
                <Typography variant="h3">{error?.errorCode ?? ''}</Typography>
                <Typography variant="body1">{error?.errorMessage ?? ''}</Typography>
            </AuthenticationStatusPage>
        );
    }

    return <AuthenticationStatusPage />;
};

IsAuthenticated.propTypes = {
    children: PropTypes.any
};

export default IsAuthenticated;