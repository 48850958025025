import React from 'react';
import { TextField, Typography, FormControl, Button, InputLabel, Select, MenuItem, Grid, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SimpleReactValidator from 'simple-react-validator';
import { useSnackbar } from 'src/components/Snackbar';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    confirmButtonContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    header: {
        backgroundColor: '#254a8e',
        display: 'flex'
    },
    cardHeader: {
        marginBottom: theme.spacing(1),
        '&:not(:first-child)': {
            marginTop: theme.spacing(2)
        }
    },
    input: {
        width: '100%',
        backgroundColor: '#fff',
        margin: '9px 0'
    },
    button: {
        padding: theme.spacing(2, 8),
        color: '#fff',
        backgroundColor: '#00A300',
        '&:hover': {
            backgroundColor: '#00D100',
            boxShadow: 'none',
        }
    }
}));

const billingTypeOptions = {
    Invoice: 'Invoice'
};

const defaultFields = {
    billingType: billingTypeOptions.Invoice,
    finnishOrganisationNumber: '',
    billingContact: {
        name: '',
        email: '',
        phone: ''
    },
    technicalContact: {
        name: '',
        email: '',
        phone: ''
    },
    organisationName: '',
    additionalInfo: '',
    invoicingAddress: ''
};

const MarketplaceForm = ({ claims, accessToken, refreshPurchase, onConfirm }) => {
    const classes = useStyles();
    const [formData, setFormData] = React.useState({ ...defaultFields });
    const [showValidationMessages, setShowValidationMessages] = React.useState(false);
    const { error: errorSnack } = useSnackbar();

    React.useEffect(() => {
        setFormData((formData) => ({
            ...formData,
            organisationName: claims?.organisationName ?? formData.organisationName,
            userId: claims?.sub ?? '',
            givenName: claims?.given_name ?? '',
            surname: claims?.family_name ?? '',
            displayName: claims?.name ?? '',
            email: claims?.email ?? '',
            mobilePhone: claims?.phoneNumber ?? '',
            countryCode: claims?.country ?? ''
        }));
    }, [claims]);

    const validator = React.useRef(new SimpleReactValidator({
        element: (message) => message,
        validators: {
            finnishOrganisationNumber: {
                message: 'Not a valid Finnish Organisation number',
                rule: (val, params) => {
                    return /^\d{7}-\d{1}$/i.test(val) && params.indexOf(val) === -1;
                },
                required: true
            }
        }
    })).current;

    if (showValidationMessages) {
        validator.showMessages();
    } else {
        validator.hideMessages();
    }

    const validationMessages = {
        organisationName: validator.message('OrganisationName', formData.organisationName, 'required|min:3'),
        finnishOrganisationNumber: validator.message('FinnishOrganisationNumber', formData.finnishOrganisationNumber, 'required|finnishOrganisationNumber'),
        billingType: validator.message('Billing Type', formData.billingType, 'required'),
        billingContactName: validator.message('Name', formData.billingContact.name, 'required'),
        billingContactEmail: validator.message('Email', formData.billingContact.email, 'required|email'),
        technicalContactName: validator.message('Name', formData.technicalContact.name, 'required'),
        technicalContactEmail: validator.message('Email', formData.technicalContact.email, 'required|email'),
        additionalInfo: validator.message('Additional Info', formData.additionalInfo, 'required')
    };

    const handleInputChanged = ({ target: { name, value } }) => {
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleConfirmClick = async () => {
        setShowValidationMessages(true);

        if (validator.allValid()) {
            const response = await fetch('https://api.arena.fi/smartdialog-marketplace-ext/v1/confirm', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                body: JSON.stringify(formData)
            });

            switch (response.status) {
                case 200:
                    refreshPurchase();
                    onConfirm();
                    break;
                case 400: {
                    const errorJson = await response.json();
                    const validationErrorMessages = errorJson?.validationFailures?.map?.((item) => item?.errorMessage ?? '')?.join?.('\n') ?? '';
                    errorSnack(validationErrorMessages);
                    break;
                }
                default:
                    errorSnack('Some unknown error occurred');
            }
        }
    };

    return (
        <div>
            <Typography variant="body1" style={{ marginBottom: 30 }}>
                Please provide the following details to proceed with the subscription. After submitting this form, an automatic process will be started.
                Currently only invoice payment is available, we are working on adding other payment types.
                <br />
                <br />
                The Sales team will be in contact to draw the contract.
                <br />
                <br />
                If you have any further questions, please contact our support at
                {' '}
                <a href="mailto:support@arenainteractive.fi" target="_blank" rel="noreferrer">support@arenainteractive.fi</a>
            </Typography>
            <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                    <FormControl className={classes.input} variant="outlined" error={Boolean(validationMessages?.billingType)}>
                        <InputLabel id="billingTypeLabel">Payment Type</InputLabel>
                        <Select
                            labelId="billingTypeLabel"
                            name="billingType"
                            label="Payment Type"
                            onChange={handleInputChanged}
                            value={formData.billingType}
                            displayEmpty
                            error={Boolean(validationMessages?.billingType)}
                        >
                            {Object.keys(billingTypeOptions).map((opt) => (
                                <MenuItem key={opt} value={opt}>{billingTypeOptions[opt]}</MenuItem>
                            ))}
                        </Select>
                        {validationMessages?.billingType && <FormHelperText>{validationMessages?.billingType}</FormHelperText>}
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                    <TextField
                        className={classes.input}
                        label="Company Name"
                        variant="outlined"
                        name="organisationName"
                        value={formData.organisationName}
                        onChange={handleInputChanged}
                        error={Boolean(validationMessages?.organisationName)}
                        helperText={validationMessages?.organisationName ?? null}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField
                        className={classes.input}
                        label="Business ID"
                        variant="outlined"
                        name="finnishOrganisationNumber"
                        value={formData.finnishOrganisationNumber}
                        onChange={handleInputChanged}
                        error={Boolean(validationMessages?.finnishOrganisationNumber)}
                        helperText={validationMessages?.finnishOrganisationNumber ?? (
                            <a href="https://www.ytj.fi/en/index/businessid.html" target="_blank" rel="noreferrer">Finnish organisation number</a>
                        )}
                    />
                </Grid>
            </Grid>
            <Typography variant="h6" className={classes.cardHeader}>
                Invoicing Contact Details
            </Typography>
            <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                    <TextField
                        className={classes.input}
                        label="Name"
                        variant="outlined"
                        value={formData.billingContact.name}
                        onChange={({ target: { value } }) => setFormData((formData) => ({ ...formData, billingContact: { ...formData.billingContact, name: value } }))}
                        error={Boolean(validationMessages?.billingContactName)}
                        helperText={validationMessages?.billingContactName ?? null}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField
                        className={classes.input}
                        label="Email"
                        variant="outlined"
                        value={formData.billingContact.email}
                        onChange={({ target: { value } }) => setFormData((formData) => ({ ...formData, billingContact: { ...formData.billingContact, email: value } }))}
                        error={Boolean(validationMessages?.billingContactEmail)}
                        helperText={validationMessages?.billingContactEmail ?? null}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField
                        className={classes.input}
                        label="Invoicing Address"
                        variant="outlined"
                        name="invoicingAddress"
                        helperText="The invoicing address can be an email address, E-invoice address or a web page link to your Invoicing information"
                        multiline
                        minRows={2}
                        value={formData.invoicingAddress}
                        onChange={handleInputChanged}
                    />
                </Grid>
            </Grid>
            <Typography variant="h6" className={classes.cardHeader}>
                Technical Contact Details
            </Typography>
            <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                    <TextField
                        className={classes.input}
                        label="Name"
                        variant="outlined"
                        value={formData.technicalContact.name}
                        onChange={({ target: { value } }) => setFormData((formData) => ({ ...formData, technicalContact: { ...formData.technicalContact, name: value } }))}
                        error={Boolean(validationMessages?.technicalContactName)}
                        helperText={validationMessages?.technicalContactName ?? null}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextField
                        className={classes.input}
                        label="Email"
                        variant="outlined"
                        value={formData.technicalContact.email}
                        onChange={({ target: { value } }) => setFormData((formData) => ({ ...formData, technicalContact: { ...formData.technicalContact, email: value } }))}
                        error={Boolean(validationMessages?.technicalContactEmail)}
                        helperText={validationMessages?.technicalContactEmail ?? null}
                    />
                </Grid>
            </Grid>
            <TextField
                className={classes.input}
                label="Additional Info"
                variant="outlined"
                name="additionalInfo"
                helperText="Please describe your user or/and business case so we can easier offer you the right product"
                multiline
                minRows={4}
                value={formData.additionalInfo}
                onChange={handleInputChanged}
                error={Boolean(validationMessages?.additionalInfo)}
            />
            <div className={classes.confirmButtonContainer}>
                <Button
                    variant="outlined"
                    size="large"
                    className={classes.button}
                    onClick={handleConfirmClick}
                    disabled={showValidationMessages && !validator.allValid()}
                >
                    Confirm
                </Button>
            </div>
        </div>
    );
};

MarketplaceForm.propTypes = {
    claims: PropTypes.object.isRequired,
    accessToken: PropTypes.string.isRequired,
    refreshPurchase: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired
};

export default MarketplaceForm;