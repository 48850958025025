import { colors } from '@material-ui/core';

const white = '#FFFFFF';

export default {
    primary: {
        contrastText: white,
        dark: '#104277',
        main: '#7030A0',
        light: '#165da8'
    },
    secondary: {
        contrastText: white,
        dark: '#7e0099',
        main: '#8f68d5',
        light: colors.blue.A400
    },
    tertiary: {
        main: '#8f68d5'
    },
    success: {
        main: '#00d594'
    },
    error: {
        contrastText: white,
        dark: colors.red[900],
        main: colors.red[600],
        light: colors.red[400]
    },
    warning: {
        main: colors.yellow[700]
    },
    text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
        link: colors.blue[600]
    },
    link: colors.blue[800],
    icon: colors.blueGrey[600],
    background: {
        default: '#f0f0f0',
        paper: white,
        gray: '#505050',
        lightGray: '#848484',
        blue: '#254a8e',
        green: '#61d695'
    },
    divider: colors.grey[200],
    white,
    status: {
        dialog: {
            new: {
                main: '#505050'
            },
            active: {
                main: '#00d594'
            },
            unread: {
                main: '#fff176'
            },
            read: {
                main: '#254a8e'
            },
            closed: {
                main: '#00d594'
            },
            expired: {
                main: colors.red[400]
            },
        },
        delivered: {
            main: '#00d594'
        },
        pending: {
            main: '#505050'
        },
        failed: {
            main: colors.red[600]
        },
        sent: {
            main: '#0083f2'
        },
        created: {
            main: '#0083f2'
        },
        redeemed: {
            main: '#00d594'
        },
        committed: {
            main: '#00d594'
        },
        voided: {
            main: colors.red[600]
        },
        unused: {
            main: '#9d9d9d'
        },
        recycled: {
            main: '#575656'
        },
        reserved: {
            main: '#575656'
        },
        cancelled: {
            main: '#da8e00'
        }
    },
    doughnutCategoryColors: [
        '#7e0099',
        '#7030A0',
        '#8f68d5',
        '#505050',
        '#254a8e',
        '#000000'
    ]
};